import { useAuth0 } from '@auth0/auth0-react';
import React, { useState, useEffect,useMemo } from 'react';
import axios from 'axios';

//custom components and imports
import {EnrollmentTable} from "../components/MainPageComponents/AdminPanelTable";
import {useNotificationTitle  } from "../Script_and_Effects/BlinkingNotification"
import {EnrollmentForm} from "../components/MainPageComponents/enrollmentEditForm";
import "../Styles/Fonts/_fonts.scss"
import AuthConfig from "../components/AuthConfig"
import VideoBackground from "../components/MainPageComponents/VideoBackground";
import mainLogo from "../Multimedia/Logo/MainLogo.png"


//mui imports
import {
    Table, TableBody, TableCell, TableContainer, TableRow, Paper,
    Button, Modal, Box, Typography, TextField, Menu, MenuItem,
    LinearProgress,InputAdornment,Badge, Container,Divider
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

    //Icons
import CircularProgress from '@mui/material/CircularProgress';
import RefreshIcon from '@mui/icons-material/Refresh';
import TimerIcon from '@mui/icons-material/Timer';


import {
    Search as SearchIcon
} from '@mui/icons-material';




const MainPage = () => {
    const { user, logout } = useAuth0();
    const [enrollments, setEnrollments] = useState([]);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [openDetailsModal, setOpenDetailsModal] = useState(false);
    const [currentEnrollment, setCurrentEnrollment] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [unadmittedPage, setUnadmittedPage] = useState(1);
    const [admittedPage, setAdmittedPage] = useState(1);
    const [unadmittedRowsPerPage, setUnadmittedRowsPerPage] = useState(5);
    const [admittedRowsPerPage, setAdmittedRowsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    const [error, setError] = useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [FetchTime,setFetchTime] = useState(2)
    const [inputValue, setInputValue] = useState(FetchTime);

    const URL = `${AuthConfig.DataRetrieveIP}/api/enrollment/`;
    const avatarUrls = Array(20).fill(0).map((_, index) => require(`../Multimedia/Avatars/${index + 1}.png`));
    const randomAvatarIndices = useMemo(() => {
        return Array(enrollments.length).fill(0).map(() => Math.floor(Math.random() * avatarUrls.length));
    }, [enrollments]);

    // converts input time to minutes
    const timeConvertor = (milliseconds) => {
        return ( milliseconds * 60000);
    }





    useEffect(() => {
        const TimeInMilliseconds = timeConvertor(FetchTime);
        fetchEnrollments();
        const interval = setInterval(fetchEnrollments, TimeInMilliseconds); // Set interval to fetch data
        console.log("useEffect called, interval set for:", FetchTime, "minutes");

        return () => clearInterval(interval);
    }, [FetchTime]);


    const fetchEnrollments = async () => {

        try {
            const response = await axios.get(URL);
            setEnrollments(response.data);
            console.log("FetchEnrollments called, interval set for:", FetchTime, "minutes");

        } catch (error) {
             console.error('Error fetching enrollments:', error);
        }
    };

    const handleAdmissionChange = async (enrollment) => {
        try {
            const updatedEnrollment = { ...enrollment, admitted: !enrollment.admitted };
            await axios.put(`${URL}${enrollment.email}/`, updatedEnrollment);
            fetchEnrollments();
        } catch (error) {
            console.error('Error updating admission status:', error);
        }
    };


    const handleEdit = (enrollment) => {
        setCurrentEnrollment({...enrollment, originalEmail: enrollment.email});
        setOpenEditModal(true);
        setAnchorEl(null);
    };

    const handleDelete = (enrollment) => {
        setCurrentEnrollment(enrollment);
        setOpenDeleteModal(true);
        setAnchorEl(null);
    };

    const handleSave = async () => {
        try {
            const originalEmail = currentEnrollment.originalEmail || currentEnrollment.email;
            await axios.put(`${URL}${originalEmail}/`, currentEnrollment);
            setOpenEditModal(false);
            fetchEnrollments();
        } catch (error) {
            console.error('Error updating enrollment:', error);
        }
    };

    const handleConfirmDelete = async () => {
        try {
            await axios.delete(URL, { data: { email: currentEnrollment.email } });
            setOpenDeleteModal(false);
            fetchEnrollments();
        } catch (error) {
            console.error('Error deleting enrollment:', error);
        }
    };

    const handleDetailsClick = (enrollment) => {
        setCurrentEnrollment(enrollment);
        setOpenDetailsModal(true);
    };

    const handleMenuClick = (event, enrollment) => {
        setAnchorEl(event.currentTarget);
        setCurrentEnrollment(enrollment);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    // new methods

    const handleChangeUnadmittedPage = (event, newPage) => {
        setUnadmittedPage(newPage);
    };

    const handleChangeAdmittedPage = (event, newPage) => {
        setAdmittedPage(newPage);
    };

    const handleChangeUnadmittedRowsPerPage = (event) => {
        setUnadmittedRowsPerPage(parseInt(event.target.value, 10));
        setUnadmittedPage(1);
    };

    const handleChangeAdmittedRowsPerPage = (event) => {
        setAdmittedRowsPerPage(parseInt(event.target.value, 10));
        setAdmittedPage(1);
    };



    //new methods



    const sortedEnrollments = useMemo(() => {
        return [...enrollments].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    }, [enrollments]);

    const filteredEnrollments = sortedEnrollments.filter(enrollment =>
        Object.values(enrollment).some(value =>
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );
    // Enrollments filtered
    const unadmittedEnrollments = filteredEnrollments.filter(enrollment => !enrollment.admitted);
    const admittedEnrollments = filteredEnrollments.filter(enrollment => enrollment.admitted);


    //----------------- refresh timer set ---------------
    const isValid = (value) => {
        return /^\d+$/.test(value);
    };

    const handleChangeRefreshInput = (event) => {
        const value = (event.target.value);
        setInputValue(value);
    };

    const handleButtonClick = () => {
        if (isValid(inputValue)) {
            setFetchTime(Number(inputValue)); // Update FetchTime with the input value (in minutes)
            console.log("FetchTime set to:", inputValue, "minutes");
            setError(false);
        } else {
            setFetchTime(2); // Set to default value if invalid
            setError(true);
        }
        setButtonDisabled(true);
        setTimeout(() => {
            setButtonDisabled(false);
        }, 5000);
    };

    //----------------- refresh timer set ---------------


    useNotificationTitle(unadmittedEnrollments);






    return (
        <>
            <VideoBackground videoSource={require("../Multimedia/VideoBackground/b.mp4")} />
            <div style={{
                backgroundColor: 'transparent',
                padding: '0',
                position: 'relative',
                zIndex: 1
            }}>
                <div>
                    <Box sx={{ textAlign: 'center', m:"1rem 1rem 0.5rem 1rem" }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5, }}>
                            <Typography variant="h3" component="h1" sx={{ display:"flex", alignItems:"center",gap:"1rem",fontFamily:"eBold", fontWeight: 'bold', fontSize: '2rem' }}>
                                <img src={mainLogo} alt="Admin Icon" width="55" height="50" />
                                ADMIN PANEL
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="h5" sx={{ mr: 2,fontFamily:"eReg" }}>
                                    WELCOME  <span style={{color:"#283ec2",fontStyle:"italic",fontFamily:"eBold", padding:"0rem 0.5rem 0rem 0.5rem" }}>{user.name}</span>
                                </Typography>
                                <Button sx={{backgroundColor:"black",fontFamily:"eBold",color:"" }} variant="contained"  color="error" onClick={() => logout({ returnTo: window.location.origin })}>
                                    Log Out
                                </Button>
                            </Box>
                        </Box>
                        <LinearProgress color="secondary" sx={{ height: 3, borderRadius: 5, backgroundColor: 'black' }} />
                        <LinearProgress color="success" sx={{ height: 3, borderRadius: 5, backgroundColor: 'black' }}  />
                        <LinearProgress color="inherit"  sx={{ height: 3, borderRadius: 5, backgroundColor: 'black' }}  />
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '3rem',
                        padding: '1rem 1rem 0rem 1rem',
                        flexDirection: { xs: 'column', md: 'row' } // switch to column view on small screens
                    }}>
                        <Typography sx={{
                            position: "absolute",
                            left:0,
                            top: "3.75rem",
                            padding: "0rem 2rem 0rem 1rem",
                            fontFamily: "eReg",
                            fontSize: "1.5rem",
                            display: "flex",
                            alignItems: "center"
                        }}>
                            Updates Every<span style={{color:"#283ec2",margin: "0rem 1rem 0rem 1rem",fontFamily:"eBold",fontSize:"5rem"}}>  {FetchTime}    </span> mins
                        </Typography>


                        <Box sx={{ display: 'flex', gap: '0.5rem',   }}>
                            <TextField
                                value={inputValue}
                                onChange={handleChangeRefreshInput}
                                label="Enter in Minutes"
                                error={error}
                                helperText={error ? 'Invalid value,Timer set to default time 2min' : `Timer value: ${FetchTime}min`}
                                id="outlined-basic"
                                variant="outlined"
                                sx={{ height: '6rem',width:"12rem" }}
                            />
                            <LoadingButton
                                sx={{
                                    backgroundColor: "black",
                                    fontFamily: "eBold",
                                    borderRadius: "0.25rem 2rem 2rem 0.25rem",
                                    height: '3.4rem',
                                    width: "12rem"
                                }}
                                variant="contained"
                                onClick={handleButtonClick}
                                disabled={isButtonDisabled}
                                loading={isButtonDisabled}  // Show loading when button is disabled
                                loadingIndicator={<CircularProgress color="primary" size={24} />}  // Customize loading icon
                                startIcon={<TimerIcon sx={{ marginLeft: '0.5rem' }} />}
                            >
                                Set Update Timer
                            </LoadingButton>
                        </Box>

                        <Box sx={{ display: 'flex', gap: '1rem' }}>

                            <LoadingButton
                                sx={{ backgroundColor: "black", fontFamily: "eBold", borderRadius:"2rem",height: '3.5rem',width:"12rem" }}
                                variant="contained"
                                disabled={isButtonDisabled}
                                loading={isButtonDisabled}  // Show loading when button is disabled
                                loadingIndicator={<CircularProgress color="primary" size={24} />}  // Customize loading icon
                                startIcon={<RefreshIcon sx={{ marginLeft: '0.5rem' }} />}

                                onClick={() => {
                                    fetchEnrollments();
                                    setButtonDisabled(true);
                                    setTimeout(() => {
                                        setButtonDisabled(false);
                                    }, 5000)
                                }}

                            >

                                Refresh Data


                            </LoadingButton>
                        </Box>

                    </Box>
                </div>

                {/*search enrollments*/}
                <div style={{margin:"0rem 1rem 0rem 1rem"}}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Search enrollments"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        style={{ marginBottom: '20px' }}
                    />

                    <Divider orientation="vertical" variant="middle" flexItem />

                    <Container sx={{padding:"1rem",textAlign:"Center"}}>
                    <Badge badgeContent={unadmittedEnrollments.length} color="error">
                        <Typography variant="h3" sx={{ fontFamily:"eBold"}}>
                            UNADMITTED STUDENTS
                        </Typography>
                    </Badge>
                    <Typography variant="subtitle1" sx={{ mb: 2, color: 'error.main',fontFamily:"eReg" }}>
                        You have {unadmittedEnrollments.length} unadmitted students.
                    </Typography>
                    </Container>
                    <EnrollmentTable
                        enrollments={unadmittedEnrollments}
                        handleAdmissionChange={handleAdmissionChange}
                        handleDetailsClick={handleDetailsClick}
                        handleMenuClick={handleMenuClick}
                        page={unadmittedPage}
                        rowsPerPage={unadmittedRowsPerPage}
                        handleChangePage={handleChangeUnadmittedPage}
                        handleChangeRowsPerPage={handleChangeUnadmittedRowsPerPage}
                        avatarUrls={avatarUrls}
                        randomAvatarIndices={randomAvatarIndices}
                        isunadmitted={+true}
                    />

                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Container sx={{padding:"2rem",textAlign:"Center"}}>
                        <Typography variant="h3" sx={{ fontFamily:"eBold"}}>
                            ADMITTED STUDENTS
                        </Typography>
                    </Container>
                    <EnrollmentTable
                        enrollments={admittedEnrollments}
                        handleAdmissionChange={handleAdmissionChange}
                        handleDetailsClick={handleDetailsClick}
                        handleMenuClick={handleMenuClick}
                        page={admittedPage}
                        rowsPerPage={admittedRowsPerPage}
                        handleChangePage={handleChangeAdmittedPage}
                        handleChangeRowsPerPage={handleChangeAdmittedRowsPerPage}
                        avatarUrls={avatarUrls}
                        randomAvatarIndices={randomAvatarIndices}
                        isunadmitted={+false}
                    />


                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        sx={{
                            '& .MuiMenuItem-root': {
                                transition: 'all 0.3s ease-in-out', // add transition effect
                            },
                        }}
                    >
                        <MenuItem
                            onClick={() => handleEdit(currentEnrollment)}
                            sx={{
                                '&:hover': {
                                    backgroundColor: '#e0e0e0', // light grey background on hover
                                    color: '#007bff', // blue text on hover
                                },
                            }}
                        >
                            Edit
                        </MenuItem>
                        <MenuItem
                            onClick={() => handleDelete(currentEnrollment)}
                            sx={{
                                color: '#ff0000', // red text
                                '&:hover': {
                                    backgroundColor: '#ff0000', // red background on hover
                                    color: '#ffffff', // white text on hover
                                },
                            }}
                        >
                            Delete
                        </MenuItem>
                    </Menu>

                    <Modal open={openEditModal} onClose={() => setOpenEditModal(false)}>
                        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '80%', bgcolor: 'background.paper', boxShadow: 24, p: "2rem",borderRadius:"2rem" }}>
                            <Typography variant="h4" component="h2" sx={{color:"white",backgroundColor:"black",borderRadius:"2rem", marginBottom:"1rem", padding:"1rem",textAlign:"center",fontFamily:"eBold",transform:"upperCase"}}>Edit Student Details</Typography>
                            <EnrollmentForm
                                currentEnrollment={currentEnrollment}
                                setCurrentEnrollment={setCurrentEnrollment}
                            />
                            <Box sx={{ textAlign: 'center', marginTop: '1rem' }}>
                                <Button
                                    sx={{
                                        padding: '0.5rem 2rem',
                                        borderRadius:"0.5rem",
                                        color:"white",
                                        backgroundColor: '#388e3c', // Light blue color
                                        '&:hover': {
                                            backgroundColor: '#71ff95', // Dark blue color
                                            color:"white",
                                            fontWeight:"Bold",
                                            transition: 'background-color 0.3s ease-in-out, color 0.3s ease-in-out, font-family 0.3s ease-in-out',
                                        },
                                    }}
                                    onClick={handleSave}
                                >
                                    Save
                                </Button>
                            </Box>
                        </Box>
                    </Modal>

                    <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
                        <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            borderRadius: '16px', // rounded corners
                        }}>
                            <Typography variant="h6" component="h2" sx={{ mb: 2, fontWeight: 'bold' }}>
                                Are you sure you want to delete this enrollment?
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button
                                    onClick={handleConfirmDelete}
                                    variant="contained"
                                    color="error" // red color


                                    sx={{
                                        color:'red',
                                        backgroundColor: '#ffcaca',
                                        '&:hover': {
                                            backgroundColor: '#ff3737', // darker red on hover
                                            color:'white',
                                        },
                                    }}
                                >
                                    Yes, Delete
                                </Button>
                                <Button
                                    onClick={() => setOpenDeleteModal(false)}
                                    variant="contained"
                                    color="success" // green color
                                    sx={{
                                        '&:hover': {
                                            backgroundColor: '#34c759', // darker green on hover
                                        },
                                    }}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Modal>

                    <Modal
                        sx={{
                            bgColor:'red'
                        }}
                        open={openDetailsModal} onClose={() => setOpenDetailsModal(false)}>
                        <Box sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: { xs: '90%', sm: '60%', md: '40%', lg: '30%' }, // adjust width based on screen size
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            borderRadius: "2rem",
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',

                        }}>
                            <Typography variant="h5" component="h2" sx={{ mb: 2, fontWeight: 'bold' }}>Enrollment Details</Typography>
                            <TableContainer component={Paper} sx={{ borderRadius: "1rem" }}>
                                <Table sx={{ minWidth: 400 }}>
                                    <TableBody>
                                        {currentEnrollment && Object.entries(currentEnrollment).map(([key, value]) => (
                                            <TableRow key={key}>
                                                <TableCell component="th" scope="row" sx={{ textTransform: 'capitalize', color: 'white', bgcolor:"black" }}>{key}</TableCell>
                                                <TableCell align="right" sx={{ color: 'black', bgcolor:"lightgray" }}>
                                                    {
                                                        key.toLowerCase() === "admitted" ? (value ? "Yes" : "No") :
                                                            key.toLowerCase() === "createdat" ? new Date(value).toLocaleString('en-GB', {
                                                                    day: '2-digit',
                                                                    month: '2-digit',
                                                                    year: 'numeric',
                                                                    hour: '2-digit',
                                                                    minute: '2-digit',
                                                                }) :
                                                                value
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default MainPage;