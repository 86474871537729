import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import authConfig from './components/AuthConfig';

const root = createRoot(document.getElementById('root'));


root.render(
    <Auth0Provider
        domain={authConfig.domain}
        clientId={authConfig.clientId}
        authorizationParams={{
            redirect_uri: window.location.origin

        }}
        audience={authConfig.audience}

    >
        <App />
    </Auth0Provider>
);
