import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';

const VideoBackgroundWrapper = styled(Box)({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    overflow: 'hidden',
});

const Video = styled('video')({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
});

const VideoBackground = ({ videoSource }) => {
    return (
        <VideoBackgroundWrapper>
            <Video autoPlay loop muted playsInline>
                <source src={videoSource} type="video/mp4" />
            </Video>
        </VideoBackgroundWrapper>
    );
};

export default VideoBackground;