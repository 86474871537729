import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Container, Typography, Box, SvgIcon } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import VideoBackground from "../components/MainPageComponents/VideoBackground";
import "../Styles/LoginPage/Button.scss"
import "../Styles/Fonts/_fonts.scss"

const LoginPage = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <>
            <VideoBackground videoSource={require("../Multimedia/VideoBackground/b1.mp4")} />
            <div style={{
                backgroundColor: 'transparent',
                padding: '20px',
                position: 'relative',
                zIndex: 1
            }}>
        <Container maxWidth="sm" sx={{ mt: 3, mb: 3 }}>
            <Box sx={{ textAlign: 'center', mb: 5 }}>
                <SvgIcon component={LockIcon} inheritViewBox sx={{ fontSize: 50, color: 'primary.main' }} />
                <Typography
                    sx={{
                        mt: 3,
                        padding:"0.5rem",
                        borderRadius:"2rem",
                        fontSize: "3rem",
                        fontWeight: 'bold',
                        fontFamily:"eBold",
                        background: 'linear-gradient(45deg, #FF69B4, #FF8ED6, #D774FF, #6C63FF, #4A7AFF, #0096FF, #00BFFF, #00E5FF, #00FFD5)',
                        backgroundSize: '200% 200%',
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                        color: 'transparent',
                        WebkitTextFillColor: 'transparent',
                        animation: 'liveGradient 5s ease infinite',
                        '&:hover': {
                            animation: 'liveGradientHover 5s ease infinite',
                        },
                    }}
                    variant="h2" gutterBottom>
                    Admin Panel Login
                </Typography>
                <Typography
                    sx={{
                        fontFamily:"eReg",
                        color: 'black',
                    }}
                    variant="h4" gutterBottom>
                    Enter Your Credentials to Access the ADMIN PANEL
                </Typography>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
                <Button
                    variant="contained"
                    size="large"
                    onClick={() => loginWithRedirect()}
                    sx={{
                        mt: 3,
                        padding:"0.5rem",
                        borderRadius:"2rem",
                        fontSize: 18,
                        fontWeight: 'bold',
                        background: 'linear-gradient(45deg, #FF69B4, #FF8ED6, #D774FF, #6C63FF, #4A7AFF, #0096FF, #00BFFF, #00E5FF, #00FFD5)',
                        backgroundSize: '200% 200%',
                        animation: 'liveGradient 5s ease infinite',
                        '&:hover': {
                            animation: 'liveGradientHover 5s ease infinite',
                        },
                    }}
                >
                    <Typography sx={{fontFamily:"eBold",fontSize:"3.75rem"}}>
                    Goto LOGIN PAGE
                    </Typography>
                </Button>
            </Box>
        </Container>
    </div>
    </>
    );
};

export default LoginPage;