// EnrollmentForm.js
import React from 'react';
import { TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';


const EnrollmentForm = ({ currentEnrollment, setCurrentEnrollment }) => {

    const handleChange = (key, value) => {
        setCurrentEnrollment({
            ...currentEnrollment,
            [key]: value,
        });
    };

    return (
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
                {currentEnrollment && Object.keys(currentEnrollment).map((key) => {
                    if (key === 'originalEmail' || key === 'createdAt' ) {
                        // Skip rendering for "originalEmail"
                        return null;
                    }
                   else if (key === 'admitted') {
                        return (
                            <FormControl key={key} style={{ flexBasis: '30%' }}>
                                <InputLabel>Admitted</InputLabel>
                                <Select
                                    value={currentEnrollment[key]}
                                    label="Admitted"
                                    onChange={(e) => handleChange(key, e.target.value)}
                                >
                                    <MenuItem value="true">Yes</MenuItem>
                                    <MenuItem value="false">No</MenuItem>
                                </Select>
                            </FormControl>
                        );
                    } else {
                        return (
                            <TextField
                                key={key}
                                label={key}
                                value={currentEnrollment[key]}
                                onChange={(e) => handleChange(key, e.target.value)}
                                margin="normal"
                                style={{ flexBasis: '30%' }}
                            />
                        );
                    }
                })}
            </div>

    );
};

export {EnrollmentForm};