import { useEffect, useMemo, useRef } from 'react';

const useNotificationTitle = (unadmittedEnrollments, baseTitle = 'Admin Panel') => {
    const count = unadmittedEnrollments.length;

    const { title, faviconIndex } = useMemo(() => {
        const title = count > 0 ? `(${count}) new notification${count !== 1 ? 's' : ''}` : baseTitle;
        const faviconIndex = Math.min(count, 10);
        return { title, faviconIndex };
    }, [count, baseTitle]);

    const prevTitleRef = useRef(document.title);
    const prevFaviconRef = useRef(document.querySelector("link[rel~='icon']")?.href);

    useEffect(() => {
        if (title !== prevTitleRef.current) {
            document.title = title;
            prevTitleRef.current = title;
        }

        const newFaviconHref = require(`../Multimedia/Icons/notification_icon/icon_${faviconIndex}.ico`);
        if (newFaviconHref !== prevFaviconRef.current) {
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.head.appendChild(link);
            }
            link.href = newFaviconHref;
            prevFaviconRef.current = newFaviconHref;
        }
    }, [title, faviconIndex]);

    return null;
};

export { useNotificationTitle };