import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
     Box,IconButton, MenuItem,
    Pagination, Select,tableCellClasses,
    Avatar,Checkbox,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import "../../Styles/Fonts/_fonts.scss"

import {
    Email as EmailIcon,
    Phone as PhoneIcon,
    Videocam as VideocamIcon,
    MoreVert as MoreVertIcon,
} from '@mui/icons-material';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "black",
        color: theme.palette.common.white,
        fontFamily:"eBold",
        fontSize: "1rem",
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily:"Thin",
        fontSize: "1.00rem",
        transition: 'font-style 0.2s'
    },
    '&:hover': {
        fontFamily:"eBold",
    }
}));

const StyledTableRow = styled(TableRow)(({ theme, isunadmitted }) => ({
    transition: 'transform 0.2s, background-color 0.2s',
    backgroundColor: isunadmitted ? '#fff0f0' : 'inherit',
    '&:hover': {
        backgroundColor: isunadmitted ? '#ffe0e0' : '#d5d5d5',
        fontSize: 16,
    },
}));

const StyledBox = styled(Box)(({ theme, isunadmitted }) => ({
    transition: 'background-color 0.2s',
    backgroundColor: isunadmitted ? '#fff0f0' : 'inherit',
    padding: "1rem",
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'

}));

const EnrollmentTable = ({ enrollments, handleAdmissionChange, handleDetailsClick, handleMenuClick, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage, avatarUrls, randomAvatarIndices, isunadmitted }) => {
    const startIndex = (page - 1) * rowsPerPage;
   return (
        <TableContainer component={Paper}>
            <Table stickyHeader sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead sx={{ backgroundColor: '#f0f0f0', borderRadius: '2rem' }}>
                    <TableRow>
                        <StyledTableCell sx={{ fontWeight: 'bold',textAlign:"center" }}>No</StyledTableCell>
                        <StyledTableCell sx={{ fontWeight: 'bold',textAlign:"center" }}>Admit</StyledTableCell>
                        <StyledTableCell sx={{ fontWeight: 'bold',textAlign:"center" }}>Name</StyledTableCell>
                        <StyledTableCell sx={{ fontWeight: 'bold',textAlign:"center" }}>Email</StyledTableCell>
                        <StyledTableCell sx={{ fontWeight: 'bold',textAlign:"center" }}>Phone No</StyledTableCell>
                        <StyledTableCell sx={{ fontWeight: 'bold',textAlign:"center" }}>Plan Type</StyledTableCell>
                        <StyledTableCell sx={{ fontWeight: 'bold',textAlign:"center" }}>Courses</StyledTableCell>
                        <StyledTableCell sx={{ fontWeight: 'bold',textAlign:"center" }}>Video Platform</StyledTableCell>
                        <StyledTableCell sx={{ fontWeight: 'bold',textAlign:"center" }}></StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {enrollments.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((enrollment, index) => (
                        <StyledTableRow key={enrollment.email} sx={{ fontWeight: 'bold' }} isunadmitted={isunadmitted}>
                        <StyledTableCell sx={{ fontWeight: 'bold',fontFamily:"eBold",cursor:"pointer",textAlign:"center" }}>
                            {startIndex + index + 1}
                        </StyledTableCell>
                        <StyledTableCell>
                                <Checkbox
                                    checked={enrollment.admitted}
                                    onChange={() => handleAdmissionChange(enrollment)}
                                    color="primary"
                                />
                            </StyledTableCell>
                            <StyledTableCell onClick={() => handleDetailsClick(enrollment)} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center',padding:"1.75rem" }}>
                                <Avatar src={avatarUrls[randomAvatarIndices[index % avatarUrls.length]]} sx={{ mr: 1 }} />
                                {enrollment.fullName}
                            </StyledTableCell>
                            <StyledTableCell style={{ cursor: 'pointer', color: "#001b77" }}>
                                <a href={`mailto:${enrollment.email}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <EmailIcon sx={{ mr: 1 }} />
                                        {enrollment.email}
                                    </Box>
                                </a>
                            </StyledTableCell>
                            <StyledTableCell style={{ cursor: 'pointer', color: "#007706" }}>
                                <a href={`tel:${enrollment.phoneNumber}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <PhoneIcon sx={{ mr: 1 }} />
                                        {enrollment.phoneNumber}
                                    </Box>
                                </a>
                            </StyledTableCell>
                            <StyledTableCell>{enrollment.planType}</StyledTableCell>
                            <StyledTableCell>{enrollment.courses}</StyledTableCell>
                            <StyledTableCell style={{ color: "#770000" }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <VideocamIcon sx={{ mr: 1 }} />
                                    {enrollment.videoPlatform}
                                </Box>
                            </StyledTableCell>
                            <StyledTableCell sx={{ width: 10, padding: 0 }}>
                                <IconButton onClick={(event) => handleMenuClick(event, enrollment)}>
                                    <MoreVertIcon />
                                </IconButton>
                            </StyledTableCell>

                        </StyledTableRow >
                    ))}
                </TableBody>
            </Table>
            <StyledBox isunadmitted={isunadmitted} sx={{ }}>
                <Select
                    value={rowsPerPage}
                    onChange={handleChangeRowsPerPage}
                >
                    {[5, 10, 25].map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
                <Pagination
                    count={Math.ceil(enrollments.length / rowsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                />
            </StyledBox>
        </TableContainer>
    );
};

export {EnrollmentTable};

