import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LoginPage from './Pages/LoginPage';
import MainPage from './Pages/MainPage';
import {
    Box,
    Skeleton,
    Fade,
    LinearProgress,
    CircularProgress,
    Typography
} from '@mui/material';

const App = () => {
    const { isLoading, isAuthenticated, error } = useAuth0();

    if (isLoading) {
        return (
            <Box sx={{ textAlign: 'center', py: 5 }}>
                <Fade in={true} style={{ transitionDelay: '500ms' }}>
                    <CircularProgress size={50} thickness={4} sx={{ mb: 2 }} />
                </Fade>
                <Skeleton variant="rectangular" width={200} height={50} sx={{ mb: 2 }} />
                <LinearProgress sx={{ width: '50%', mx: 'auto' }} />
            </Box>
        );
    }

    if (error) {
        return (
            <Box sx={{ textAlign: 'center', py: 5 }}>
                <Typography variant="h5" gutterBottom>
                    An error occurred: {error.message}
                </Typography>
            </Box>
        );
    }

    return (
        <>
            {isAuthenticated ? <MainPage /> : <LoginPage />}
        </>
    );
};

export default App;