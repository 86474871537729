// //Online auth0 server
const AuthConfig = {
    domain: 'dev-f8kuhvmm7gdapoia.us.auth0.com',
    clientId: 'yH1y5se1u7GBoLStE0yUBnABS9m7DtfB',
    audience: 'https://dev-f8kuhvmm7gdapoia.us.auth0.com/api/v2/',
    DataRetrieveIP:'https://backend.luminaryquran.com',

};

// //Offline auth0 app details
// const AuthConfig = {
//     domain: 'dev-f8kuhvmm7gdapoia.us.auth0.com',
//     clientId: 'YWgn7tXqWTleFOY2BBM8mzRb4dn0PY0D',
//     audience: 'https://api.luminaryquran.com/',
//     DataRetrieveIP:'http://192.168.100.10:8000',
//
// };

export default AuthConfig;
